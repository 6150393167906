import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>Odborník pre informatizáciu štátu: Využívanie elektronických schránok <br />má rezervy</h1>
    <p>
    Elektronickú schránku na ústrednom portáli verejnej správy, slúžiacu na komunikáciu s verejnou
    správou, má zriadenú každý obyvateľ Slovenska, právnický subjekt evidovaný na Slovensku, ako aj
    všetky inštitúcie a orgány verejnej moci. Napriek tomu sa stále množstvo komunikácie vybavuje
    osobne či poštou. Z toho dôvodu je funkcionalita portálu laickou verejnosťou často spochybňovaná
    a vnímaná ako nedokončená. O tom, ako schránky je možné lepšie používať, sme hovorili s Rolandom
    Takácsom, biznis konzultantom, ktorý spolupracoval na tvorbe <a href="https://www.slovensko.sk/sk/titulna-stranka" target="blank">Ústredného
        portálu verejnej správy</a>.
    </p>
    <p>
    <strong>Využívajú sa elektronické schránky dostatočne?</strong>
    <br />
    Z pohľadu návratnosti investície môžem povedať, že schránky sa využívajú dostatočne. Za rok 2020
    bolo prostredníctvom schránok vymenených viac ako 30 miliónov správ, ktoré by sa posielali
    doporučene alebo do vlastných rúk. To predstavuje ročnú úsporu iba na poštovnom viac ako
    44miliónov eur.  A samozrejme, sú tu tiež úspory na papieri, tlači, obálkach a najmä na čase
    zamestnancov a občanov. Neznamená to však, že by sa schránky nemohli používať ešte viac.
    </p>
    <p>
    <strong>Ak sa dajú nájsť rezervy, kde ich vidíte?</strong><br />
    Štát by mal začať dodržiavať zákony, ktoré sám pripravil. Inštitúcie majú povinnosť komunikovať
    s právnickými subjektami elektronicky, nie prostredníctvom listových zásielok, ako sa to stále
    deje.<br />
    Ďalšou veľkou skupinou sú živnostníci. Nemyslím si, že by mali mať elektronickú komunikáciu
    s verejnou správou povinne. Podnikateľom môže byť napríklad studniar, ktorý je výborný odborník,
    ale počítačová zručnosť nie je nevyhnutná pre výkon jeho podnikania. Propagácia výhod a možností
    elektronickej komunikácie prostredníctvom schránok by však živnostníkov mohla motivovať
    k častejšej elektronickej komunikácii. Tu by mohli pomôcť napríklad aj mobilné aplikácie.
    </p>
    <p>
    <strong>A čo občania?</strong>
    <br />
    Existuje veľká skupina občanov, ktorí sú zároveň živnostníci, konatelia firiem a podobne
    a z tohto dôvodu so štátom komunikujú omnoho častejšie. Títo sú primárnymi používateľmi
    elektronických služieb, ktorí určite privítajú každé zlepšenie. Odhadom môže ísť až o 300 000
    ľudí.<br />
    Pre bežného občana je však dôležité niečo iné. Napríklad keď kúpi nehnuteľnosť a chce v nej mať
    trvalý pobyt, nie je preňho až takou úľavou, keď namiesto vypĺňania desiatok papierov bude
    vypĺňať desiatky elektronických formulárov, posielať ich na rôzne miesta a tŕpnuť, či niečo
    nezabudol alebo nevyplnil zle. Som si istý, že by bol najradšej, keby si všetko inštitúcie
    vybavili medzi sebou na pozadí a jemu dali pokoj.
    </p>
    <p>
    <strong>Je niečo, v čom by mohli schránky pomôcť aj bežným občanom, ktorí sa napríklad práve
        nesťahujú?</strong>
    <br />
    Jednou z možností by mohlo byť vytvorenie základných formulárov, rovnakých pre všetky
    inštitúcie. Ihneď mi napadnú sťažnosti, oznámenia, vyžiadanie si informácií. Sťažujúci sa občan
    by si otvoril formulár, vybral adresáta a napísal text sťažnosti. Po odoslaní na inštitúciu by
    sa mu potom nestalo, že sa mu sťažnosť zamietne pre nesplnenie formálnych požiadaviek.
    Prekvapuje ma, že sa k takémuto jednoduchému a finančne nenáročnému riešeniu doteraz
    nepristúpilo.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      